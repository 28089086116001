<template>
  <div class="template-create-container">
    <div class="container">
      <div class="reject" v-if="reject">
        <div class="flex-align" style="gap: 4px;margin-bottom: 4px">
          <img src="/static/icon/u_info-circle-error.svg" />
          <div class="body4-medium">템플릿 심사가 반려되었습니다.</div>
        </div>
        <div class="body5">{{ reject }}</div>
      </div>
      <div class="main template-title">템플릿 등록 신청</div>
      <div v-if="visible">
        <div
          class="form-item"
          v-for="(form, form_idx) in formData"
          :key="`form-${form_idx}`"
        >
          <div>
            <div class="inquiry-reg-title subtitle6">
              <template v-if="form.field !== 'checkedVal'">
                {{ form.label
                }}<span v-if="form.validate.required" class="required"> *</span>
              </template>
            </div>
            <div
              class="inquiry-reg-description body6 sub3"
              v-if="form.description"
            >
              {{ form.description }}
            </div>
          </div>
          <div class="inquiry-reg-content">
            <component
              :is="form.component_name"
              :ref="form.field"
              v-bind.sync="form"
              :useLabel="false"
            ></component>
          </div>
        </div>
      </div>
      <div class="business-container" v-if="businessFormData.length > 0">
        <div class="main template-title">사업자 정보</div>
        <div>
          <div
            class="form-item"
            v-for="(form, form_idx) in businessFormData"
            :key="`form-${form_idx}`"
          >
            <div>
              <div class="inquiry-reg-title subtitle6">
                <template v-if="form.field !== 'checkedVal'">
                  {{ form.label
                  }}<span v-if="form.validate.required" class="required">
                    *</span
                  >
                </template>
              </div>
              <div
                class="inquiry-reg-description body6 sub3"
                v-if="form.description"
              >
                {{ form.description }}
              </div>
            </div>
            <div class="inquiry-reg-content">
              <component
                :is="form.component_name"
                :ref="form.field"
                v-bind.sync="form"
                :useLabel="false"
              ></component>
            </div>
          </div>
        </div>
      </div>

      <div class="button-reg-container">
        <button class="button is-primary-light body2-medium" @click="saveTemp">
          임시저장
        </button>
        <button class="button is-primary body2-medium" @click="saveData">
          등록신청
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LpCheckBool from "../module/LpCheckBool";
import CheckboxGroup from "../module/CheckboxGroup";
import LpRadio from "../module/LpRadio";
import InputSingleFile from "../module/InputSingleFile";
import LpText from "../component/LpText";
import LpTextArea from "../component/LpTextArea";
import LpNumberUnit from "../component/LpNumberUnit";
import UserAPIMixin from "../../mixins/UserAPIMixin";
import FormMixin from "../../mixins/FormMixin";
import LpCheckBoxArray from "../component/LpCheckBoxArray";
import LpDropDown from "../LpDropDown";
import InputFile from "../module/InputFile";
import InputTech from "../module/InputTech";
import InputImgUploader from "../module/InputImgUploader";
import InputImgUploaderArray from "../module/InputImgUploaderArray";
import InputDatePeriod from "../module/InputDatePeriod";
import InputPrice from "../module/InputPrice";
import InputRate from "../module/InputRate";
import InputEditor from "../module/InputEditor";
import InputPhoneVerify from "../module/InputPhoneVerify";
import InputTemplateCategory from "../module/InputTemplateCategory";
import InputFaq from "../module/InputFaq";
export default {
  name: "TemplateCreate",
  mixins: [UserAPIMixin, FormMixin],
  components: {
    CheckboxGroup,
    InputImgUploader,
    InputImgUploaderArray,
    InputFile,
    LpDropDown,
    LpCheckBoxArray,
    LpNumberUnit,
    LpTextArea,
    LpText,
    InputSingleFile,
    LpRadio,
    LpCheckBool,
    InputDatePeriod,
    InputTech,
    InputPrice,
    InputRate,
    InputEditor,
    InputPhoneVerify,
    InputTemplateCategory,
    InputFaq,
  },
  async created() {
    if(!this.isLogin) {
      this.toast('로그인 후 이용할 수 있습니다.');
      this.$store.commit('setPrevPath', this.$route.fullPath);
      this.$router.replace('/signin');
      return;
    }
    if (this.$route.query.temp_id) {
      this.tempId = Number(this.$route.query.temp_id);
    }
    this.getTemp();
    this.getInquiryCategory();

    if (!this.isLogin) {
      this.toast("로그인이 필요합니다.");
      this.$router.push("/signin");
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.$nextTick(() => {
      this.handleResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      catArr: [],
      formData: [],
      businessFormData: [],
      tempId: null,
      visible: false,
      reject: ''
    };
  },
  methods: {
    handleResize() {
      this.formData.forEach((form) => {
        if (form.component_name.includes("input-img-")) {
          const [width, height] = this.imgRate(
            window.innerWidth > 768 ? 200 : 80
          );
          form.width = width;
          form.height = height;
        }
      });
    },
    async getInquiryCategory() {
      await this.$axios.get(`public/inquiry/source/category`).then((res) => {
        this.catArr = res.data.map((i) => {
          return {
            value: i.id,
            label: i.name,
          };
        });
      });
    },
    clickLogin() {
      this.$store.commit("setPrevPath", this.$route.fullPath);
      this.$router.push("/signin");
    },
    setForm() {
      this.formData = [
        {
          label: "카테고리",
          field: "template_categories",
          component_name: "InputTemplateCategory",
          value: [],
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "템플릿 제목",
          placeholder: "제목",
          maxLength: 100,
          field: "name",
          type: "text",
          value: "",
          component_name: "lp-text",
          errorMsg: "",
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 50,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          col: 12,
          label: "작업범위",
          component_name: "checkbox-group",
          field: "work_scope",
          value: [],
          errorMsg: "",
          option: [
            { label: "기획", value: "기획" },
            { label: "UI/UX 디자인", value: "UI/UX 디자인" },
            { label: "Front-end 개발", value: "Front-end 개발" },
            { label: "Back-end 개발", value: "Back-end 개발" },
          ],
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          col: 12,
          label: "지원 환경",
          component_name: "checkbox-group",
          field: "support_env",
          value: [],
          errorMsg: "",
          option: [
            { label: "Mobile Web", value: "Mobile Web" },
            { label: "PC Web", value: "PC Web" },
            { label: "Android", value: "Android" },
            { label: "iOS", value: "iOS" },
          ],
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "구축기간",
          field: "dev_period",
          type: "date_period",
          value: { start: "", end: "" },
          component_name: "input-date-period",
          errorMsg: "",
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          col: 12,
          label: "주요기능",
          component_name: "checkbox-group",
          field: "features",
          value: [],
          errorMsg: "",
          option: [
            { label: "PG결제", value: "PG결제" },
            { label: "위치 기반 서비스", value: "위치 기반 서비스" },
            { label: "AI", value: "AI" },
            { label: "VR", value: "VR" },
            { label: "커뮤니티", value: "커뮤니티" },
            { label: "라이브", value: "라이브" },
            { label: "크롤링/스크래핑", value: "크롤링/스크래핑" },
            { label: "전자계약", value: "전자계약" },
          ],
          validate: {
            valid_required: false,
            required: false,
          },
        },
        {
          label: "기술스택",
          value: [],
          field: "techs",
          component_name: "input-tech",
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "구축 비용",
          value: {
            price: 0,
            won_type: 0,
          },
          component_name: "input-price",
          field: "price",
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "참여율",
          value: "0",
          component_name: "input-rate",
          field: "participation_rate",
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "결과물 URL",
          placeholder: "https://",
          maxLength: 200,
          field: "link",
          type: "text",
          value: "",
          component_name: "lp-text",
          errorMsg: "",
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 200,
            min_length: 1,
            valid_required: false,
            required: false,
          },
        },
        {
          label: "템플릿 설명",
          placeholder: "내용을 입력해주세요",
          field: "info",
          value: "",
          component_name: "input-editor",
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "템플릿 대표 이미지",
          description: "최소 660 x 825px (4:5)",
          placeholder: "",
          field: "first_img",
          value: "",
          component_name: "input-img-uploader",
          width: 200,
          height: 250,
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "템플릿 스크린샷",
          description: "최소 660 x 825px (4:5)",
          placeholder: "",
          field: "imgs",
          value: [],
          component_name: "input-img-uploader-array",
          limit: 10,
          width: 200,
          height: 250,
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: "자주 묻는 질문",
          component_name: "input-faq",
          field: "faq",
          value: [],
          validate: {
            valid_required: false,
            required: false,
          },
        },
      ];
    },
    setBusinessForm() {
      if (this.user.partner && !this.user.partner.business.biz_number) {
        this.businessFormData = [
          {
            label: "대표 휴대전화 번호",
            field: "phone",
            type: "phone",
            value: "",
            component_name: "input-phone-verify",
            validate: {
              success: false,
              valid_required: true,
              required: true,
            },
          },
          {
            label: "사업자등록번호",
            field: "biz_number",
            type: "biz",
            value: "",
            component_name: "lp-text",
            maxLength: 20,
            validate: {
              valid_required: true,
              required: true,
            },
          },
          {
            label: "사업자등록증",
            field: "img_registration",
            component_name: "input-file",
            maxLength: 1,
            value: [],
            validate: {
              valid_required: true,
              required: true,
            },
            description: "",
          },
        ];
      }
    },
    getTemp() {
      this.setForm();
      this.setBusinessForm();
      if (this.$route.query.temp_id || this.$route.query.id) {
        let url = this.$route.query.temp_id
          ? `product/${this.$route.query.temp_id}`
          : `product/${this.$route.query.id}`;
        this.request.user
          .get(url)
          .then((res) => {
            this.formData.forEach((item) => {
              if (item.field === "template_categories") {
                item.value = res.data.template_categories.map((i) => i.id);
              } else if (item.field === "dev_period") {
                item.value = {
                  start: res.data.dev_start,
                  end: res.data.dev_end,
                };
              } else if (item.field === "price") {
                item.value.price = parseInt(res.data.price.price / 10000);
                item.value.won_type = res.data.price.won_type;
              } else {
                item.value = res.data[item.field];
              }
              this.visible = true;
            });
            if(res.data.request_status === 2) {
              this.reject = res.data.reject_reason;
            }
          })
          .catch(() => {
            this.visible = true;
          });
      } else {
        this.visible = true;
      }
    },
    commonParam(param) {
      param.params.category1_id = 58; // 런치팩 상품
      param.params.category2_id = 61; // 웹/앱 템플릿
      param.params.category3_id = 69; // 템플릿
      param.params.price.price *= 10000;
      param.params.enable_prod = false;
    },
    saveTemp() {
      let formData = this.cloneItem(this.formData);
      formData.forEach(item => {
        if(item.validate) {
          item.validate.required = false;
          item.validate.valid_required = false;
        }
      })
      let param = this.getParamData(formData);
      if (!param.enable) {
        return;
      }
      this.commonParam(param);
      param.params.prod_type = 1; // 임시저장
      param.params.request_status = 9;
      this.setLoading("임시저장중입니다.");
      if (this.tempId) {
        this.request.user
          .patch(`product/${this.tempId}`, param.params)
          .then(() => {
            this.clearLoading();
            this.toast("임시저장 되었습니다.");
          })
          .catch(() => {
            this.clearLoading();
            this.toast("오류가 발생하였습니다. 관리자에게 문의주세요.");
          });
      } else {
        this.request.user
          .post("product", param.params)
          .then((res) => {
            this.tempId = res.data.id;
            if(res.data.partner) {
              let user = this.user;
              user.partner = res.data.partner;
              this.$store.commit('setUser', user);
            }
            this.clearLoading();
            this.toast("임시저장 되었습니다.");
          })
          .catch(() => {
            this.clearLoading();
            this.toast("오류가 발생하였습니다. 관리자에게 문의주세요.");
          });
      }
    },
    saveData() {
      let result = this.cloneItem(this.getParamData(this.formData));
      if (!result.enable) {
        return;
      }
      if(this.$route.query.temp_id) {
        result.params.temp_id = Number(this.$route.query.temp_id);
      }
      this.commonParam(result);
      result.params.prod_type = this.$route.query.id ? 3 : 2;
      if (this.$route.query.id) {
        result.params.org_id = Number(this.$route.query.id);
      }
      if (this.businessFormData.length > 0) {
        let result2 = this.getParamData(this.businessFormData);
        if (!result2.enable) {
          return;
        }
        result.params.business = result2.params;
      }

      this.setLoading("등록중입니다.");
      if (['0', '2'].includes(this.$route.query.request_status)) {
        if(this.$route.query.request_status === '2') {
          result.params.request_status = 3;
        }
        this.request.user
          .patch(`product/${this.$route.query.id}`, result.params)
          .then((res) => {
            this.clearLoading();
            if (res.status === 200) {
              if(this.$route.query.id) {
                this.$router.replace(`template_create_done?update=true`);
              } else {
                this.$router.replace(`template_create_done`);
              }
            }
          })
          .catch((err) => {
            this.clearLoading();
            if (err.response.data.message) {
              this.toast(err.response.data.message);
            } else if (err.response.data.detail) {
              this.toast(err.response.data.detail);
            } else {
              this.toast("오류가 발생하였습니다. 관리자에게 문의주세요.");
            }
          });
      } else {
        this.request.user
          .post("product", result.params)
          .then((res) => {
            this.clearLoading();
            if (res.status === 200) {
              if(res.data.partner) {
                let user = this.user;
                user.partner = res.data.partner;
                this.$store.commit('setUser', user);
              }
              if(this.$route.query.id) {
                this.$router.replace(`template_create_done?update=true`);
              } else {
                this.$router.replace(`template_create_done`);
              }
            }
          })
          .catch((err) => {
            this.clearLoading();
            if (err.response.data.message) {
              this.toast(err.response.data.message);
            } else if (err.response.data.detail) {
              this.toast(err.response.data.detail);
            } else {
              this.toast("오류가 발생하였습니다. 관리자에게 문의주세요.");
            }
          });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.template-create-container
  padding 64px 0
  background-color $gray3

.template-title
  font-size 22px
  font-weight 700
  padding-bottom 40px

.container
  background-color white
  border-radius 16px
  padding 40px
  margin 40px auto

.form-item
  display flex
  gap 8px
  padding 24px 0
  border-top 1px solid $gray1
  &:first-child
    border-top none

.inquiry-reg-title
  width 172px
  padding 12px 0

.inquiry-reg-content
  flex 1

.required
  color $error

.business-container
  border-top 1px solid $gray1
  padding-top 40px
  margin-top 40px

.button-reg-container
  display flex
  justify-content center
  flex-wrap wrap
  gap 10px
  margin-top 40px

.button-reg-container button
  width 242px
  height 48px

.reject
  padding 12px
  border-radius 8px
  background-color $error50
  color $error
  margin-bottom 40px

@media (max-width: 600px)
  .template-create-container
    padding 60px 16px
  .container
    margin 16px 0
    padding 16px
  .template-title
    font-size 18px
  .form-item
    flex-direction column
    .inquiry-reg-title
      padding 0
      width 100%
</style>
